import React from "react";
import Header from "./Header";
import {Helmet} from "react-helmet";


class NotFound extends React.Component {
    render() {
        return (
            <div className="h-100">
                <Helmet>
                    <title>Страница не найдена</title>
                </Helmet>
                <Header background="/images/zdver-crowd.jpg" content='Страница не найдена' full/>
            </div>
        )
    }
}

export default NotFound;
