import React from "react";


interface HookahTableProps {
    data: any,
    title: string,
    label: string,
    note: string,
}

/*  propTypes: {
    data: propTypes.array.isRequired,
    title: propTypes.string.isRequired,
    label: propTypes.string,
    note: propTypes.string
  },*/

class HookahTable extends React.Component<HookahTableProps> {
    static defaultProps = {
        data: [],
        title: "",
        label: "",
        note: "",
    }

    renderTable() {
        return this.props.data.map((row: any, key: number) => {
            const descriptionColumn = row.description === undefined ? null : `<td>{row.description}</td>`;

            return (
                <tr key={key}>
                    <td className="col-xs-3">{row.name}</td>
                    <td className="col-xs-2">{row.price + ' ₽'}</td>
                    {descriptionColumn}
                </tr>
            );
        });
    }

    renderNote() {
        if (this.props.note) {
            return <small>{`* ${this.props.note}`}</small>;
        }
    }

    render() {
        return (
            <div className="hookah-table">
                <h2>{this.props.title}</h2>
                <span className="hookah-table-label">{this.props.label}</span>
                <table className="hookah-table">
                    {this.renderTable()}
                </table>
                {this.renderNote()}
            </div>
        );
    }
}

export default HookahTable;
