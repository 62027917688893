import React from "react";
import {faEnvelope, faMapMarked, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Footer extends React.Component {

    render() {
        return <footer className="footer-container">
            <div className="container">
                <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-1 footer-logotype-container">
                        <div className="footer-logotype"/>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-7">
                        <div className="footer-short-info">
                            <span className="name" itemProp="name">Антикафе Wooden Door</span>
                            <br/>
                            <time className="footer-time" itemProp="openingHours" dateTime="Mo-Su">
                                Мы открыты с 12.00 до 00.00, в пятницу и субботу до 6.00 утра.
                            </time>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3">
                        <a className="footer-contacts-item"
                           itemProp="address"
                           itemScope itemType="https://schema.org/PostalAddress"
                           href="https://yandex.ru/maps/213/moscow/?ll=37.637601%2C55.770801&mode=poi&poi%5Bpoint%5D=37.637681%2C55.771568&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D10875350465&z=17.24"
                           target="_blank"
                           rel="noreferrer">
                            <FontAwesomeIcon icon={faMapMarked}/>
                            <span itemProp="streetAddress"> Сухаревская площадь, 16/18 стр. 1</span>,
                            <span itemProp="addressLocality"> Москва</span>
                        </a>
                        <div className="footer-contacts-item" itemProp="telephone">
                            <FontAwesomeIcon icon={faPhone}/>
                            <span>+7 (495) 748-9338</span>
                        </div>
                        <a className="footer-contacts-item footer-email" href="mailto:info@wdndoor.com"
                           itemProp="email">
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <span> info@wdndoor.com</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>;
    }
}

export default Footer;
