import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import HookahTable from "./HookahTable";
import {Helmet} from "react-helmet";

const items = require('./hookah_items.json');

class HookahMenu extends React.Component {

    render() {
        return (
            <div className="menu-container">
                <Helmet>
                    <title>Кальянное меню</title>
                </Helmet>
                <Header background="/images/zdver-crowd.jpg" content='Кальянное меню'/>
                <div className="container">
                    <p className="hookah-intro">
                        Если ты читаешь это, значит ты хочешь хорошо подымить.<br/>
                        Я здесь для того, чтобы помочь тебе сделать правильный выбор!
                    </p>
                    <p className="hookah-intro">
                        Обозначить ваши предпочтения по вкусу можно так: сладкий, кислый, свежий, или пряный.
                        Также можно добавить мяту.
                    </p>
                    <HookahTable data={items.cup} title="Содержимое чаши"/>
                    <div className="row">
                        <div className="col-sm-7">
                            <HookahTable
                                data={items.fruits}
                                title="Чаши из фруктов"
                                label="Мы умеем работать с фруктами. Со всеми. Серьезно."
                            />
                        </div>
                        <div className="col-sm-5">
                            <HookahTable
                                data={items.fills}
                                title="Наполнение колбы"
                                label="Мы можем!"
                                note="Все наполнители наливаются в заранее установленных пропорциях."
                            />
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default HookahMenu;
