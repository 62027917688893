import React from "react";
//import ContactsAddress from "./ContactsAddress";
import ContactsVideo from "./ContactsVideo";
import Navigation from "../../layout/Navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faVk} from "@fortawesome/free-brands-svg-icons"
import {faHeart} from "@fortawesome/free-solid-svg-icons"
import {Helmet} from "react-helmet";

interface ContactProps {
    //showVideo: boolean
}

interface ContactState {
    showVideo: boolean
}

class Contacts extends React.Component<ContactProps, ContactState> {

    constructor(props: any) {
        super(props);
        this.state = {showVideo: false};
    }

    static partnerLinks = [
        {url: "https://kudago.com/", title: "кудаго.ру"},
        {url: "https://2do2go.ru/", title: "2ду2го.ру"},
        {url: "https://zovem.ru/", title: "зовем.ру"},
        {url: "https://lookatme.ru/", title: "лукэтми.ру"},
        {url: "https://theoryandpractice.ru/", title: "тандп.ру"},
        {url: "https://2anticafe.com/", title: "туантикафе.ру"},
    ];

    onVideoToggle() {
        this.setState({showVideo: !this.state.showVideo});
    }

    render() {
        return (
            <div className="contacts">
                <Helmet>
                    <title>Контакты</title>
                </Helmet>

                <div className="contacts-navigation">
                    <Navigation/>
                </div>
                <ContactsVideo active={this.state.showVideo} onClick={this.onVideoToggle}/>
             {/*   <ContactsAddress active={!this.state.showVideo} onClick={this.onVideoToggle}/>*/}
                <div className="contacts-police-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-sm-7 hidden-xs">
                                <div className="contacts-hookah"/>
                            </div>
                            <div className="col-sm-5 col-md-offset-1 col-md-5 ">
                                <ul className="contacts-list">
                                    <li>
                                        <span className="contacts-item">Телефон:</span>
                                        +7 (495) 748-9338
                                    </li>
                                    <li>
                                        <span className="contacts-item">Вопросы:</span>
                                        <a className="contacts-link" href="mailto:info@wdndoor.com">info@wdndoor.com</a>
                                    </li>
                                    <li>
                                        <span className="contacts-item">Мероприятия:</span>
                                        <a className="contacts-link"
                                           href="mailto:events@wdndoor.com">event@wdndoor.com</a>
                                    </li>
                                    <li>
                                        <span className="contacts-item">Жалобы:</span>
                                        <a className="contacts-link"
                                           href="mailto:complaints@wdndoor.com">complaints@wdndoor.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contacts-social-container">
                    <a className="contacts-social facebook" href="https://www.facebook.com/antipubwoodendoor">
                        <FontAwesomeIcon icon={faFacebook} size="2x"/>
                    </a>
                    <a className="contacts-social vk" href="https://vk.com/woodendoor">
                        <FontAwesomeIcon icon={faVk} size="2x"/>
                    </a>
                    <a className="contacts-social instagram" href="https://instagram.com/door_wooden">
                        <FontAwesomeIcon icon={faInstagram} size="2x"/>
                    </a>
                </div>
                <div className="contacts-partners-container">
                    <span>Любимые партнеры</span>
                    <br/>

                    <FontAwesomeIcon icon={faHeart} className="contacts-partners-heart"/>

                    <br/>
                    {
                        Contacts.partnerLinks.map(({url, title}) =>
                            <a key={title} className="contacts-partner" href={url}>{title}</a>)
                    }

                </div>
            </div>
        );
    }
}

export default Contacts;
//module.exports = Contacts;
