import React from "react";
import Footer from "../../layout/Footer";

class PricingSlide extends React.Component {
    // TODO: лучше разобраться почему перерендеры лишние есть
    shouldComponentUpdate = () => false;

    render() {
        return (
            <section className="about-slide about-bottom-slide">
                <div className="about-price container align-content-center">
                    <div className="row">
                        <div className="about-pricing col-md-3 offset-1"/>

                        <div className="col-md-7">
                            <p> 2 рубля в минуту</p>
                        </div>
                    </div>

                </div>
                <div className="about-footer">
                    <Footer/>
                </div>
            </section>
        );
    }
}

export default PricingSlide;
