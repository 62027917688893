import React from "react";
import classNames from "classnames";

interface Props {
    active: boolean
    onClick: (...args: any[]) => any;
}


// TODO: super(props);
class ContactsVideo extends React.Component<Props> {
    static defaultProps = {
        active: false,
        onClick: {},
    }

    render() {
        const className = classNames('contacts-video-cddontainer', {active: this.props.active});
        return (
            <div className={className} onClick={this.props.onClick}>
                <iframe

                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Afe3344aa8f47c2f4b38b65ac4e4805193703479efc11996e3a81cf89780d3b13&amp;source=constructor"
                    width="100%" height="400" frameBorder="0">

                </iframe>
             </div>
        );
    }
}

export default ContactsVideo;
