import React from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import {Helmet} from "react-helmet";

// TODO: тут можно по DRY тоже сделать
class Home extends React.Component {
    render() {
        return (
            <div className="h-100">
                <Helmet>
                    <title>Wooden Door</title>
                </Helmet>
                <Header background="/images/zdver-crowd.jpg" content='Wooden Door' big/>
                <div>
                    <div className="home-hookah-container">
                        <div className="home-hookah-image text-center">
                            <img src="/images/home/home-1.jpg" alt="Кальяны"/>
                            <span className="feed-hookah-price">950 ₽</span>
                        </div>

                        <div className="home-hookah-image text-center">
                            <img src="/images/home/home-2.jpg" alt="Кальяны"/>
                            <span className="feed-hookah-price">&nbsp;600 ₽</span>
                        </div>

                    </div>
                    <div className="home-hookah-container">
                        <div className="home-hookah-image text-center">
                            <img src="/images/home/home-3.jpg" alt="Кальяны"/>
                            <span className="feed-hookah-price">800 ₽</span>
                        </div>

                        <div className="home-hookah-image text-center">
                            <img src="/images/home/home-4.jpg" alt="Кальяны"/>
                            <span className="feed-hookah-price">&nbsp;1300 ₽</span>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Home;
