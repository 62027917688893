import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import MenuSection from "./MenuSection";
import {Helmet} from "react-helmet";

const items = require('./menu_items.json');


class FoodMenu extends React.Component {
    render() {
        return (
            <div className="menu-container">
                <Helmet>
                    <title>Вкусное меню</title>
                </Helmet>
                <Header background="/images/zdver-crowd.jpg" content='Вкусное меню'/>
                <div className="container">
                    <MenuSection name="Супы" items={items.soups}/>
                    <MenuSection name="Салаты" items={items.salad}/>
                    <MenuSection name="Горячее" items={items.hot}/>
                    <MenuSection name="Воки" items={items.noodles}/>
                    <MenuSection name="Топпинги к лапше" items={items.noodlesToppings}/>
                    <MenuSection name="Дополнительные соусы" items={items.noodlesSauce}/>
                    <MenuSection name="Роллы и сеты" items={items.sushi}/>
                    <MenuSection name="Бургеры" items={items.burger}/>
                    <MenuSection name="Комбо к пиву" items={items.beerSnack}/>
                    <MenuSection name="Закуски" items={items.snack}/>
                    <MenuSection name="Напитки" items={items.drinks}/>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default FoodMenu;
