import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import FoodMenu from "./pages/FoodMenu/FoodMenu";
import Contacts from "./pages/Contacts/Contacts";
import HookahMenu from "./pages/HookahMenu/HookahMenu";
import NotFound from "./layout/NotFound";

import './css/index.scss'

const App: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path="about" element={<About/>}/>
            <Route path="/" element={<Home/>}/>,
            <Route path="/food-menu" element={<FoodMenu/>}/>,
            <Route path="/contacts" element={<Contacts/>}/>,
            <Route path="/hookah" element={<HookahMenu/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
