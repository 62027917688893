import React from "react";
import classNames from "classnames";

interface RibbonProps {
    className: string,
    title: string,
    subTitle: string,
    hideEdges: boolean,
    hideBottomEdges: boolean,
}

// TODO: super(props)
class Ribbon extends React.Component<RibbonProps> {
    static defaultProps = {
        className: '',
        title: '',
        subTitle: '',
        hideEdges: false,
        hideBottomEdges: false
    }

    renderSubTitle() {
        if (this.props.subTitle) {
            return (
                <span className="ribbon-subtitle">
                    <br/>
                    {this.props.subTitle}
                </span>
            );
        }
    }

    render() {
        const ribbonClasses = classNames('ribbon', this.props.className, {
            'hide-edges': this.props.hideEdges,
            'hide-bottom-edges': this.props.hideBottomEdges
        });

        return (
            <>
                <div className="ribbon-container">
                    <h1 className={ribbonClasses}>
                  <span className="ribbon-content" itemProp="name">
                      <span>{this.props.title}</span>
                      {this.renderSubTitle()}
                  </span>
                    </h1>
                </div>
            </>
        );
    }
}

export default Ribbon;
