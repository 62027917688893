import React from "react";

interface Props {
    title: string
    items: any
}

// TODO: super(props);
class Additionals extends React.Component<Props> {
    render() {
        return (
            <>
                <div style={{paddingLeft: 8, textAlign: 'right'}}>
                    <h3 style={{marginTop: 20, marginBottom: 0}}>{this.props.title}</h3>
                    <ul style={{listStyle: 'none', paddingLeft: 8, marginTop: 3, fontSize: 12}}
                        className="menu-section-list">
                        {
                            this.props.items.map(({name, weight, price}: any) => (
                                <li key={name}>
                                    {name}
                                    {weight || price ? ' · ' : ' '}
                                    {weight ? <span>{weight}гр</span> : ''}
                                    {weight && price ? ' · ' : ' '}
                                    {price ? <b>{price} ₽</b> : ''}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </>
        )
    }
}

export default Additionals;
