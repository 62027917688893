import React from "react";
import {NavLink} from 'react-router-dom';
import {faVk} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const classNames = require('classnames');

interface Props {
    fixed: boolean,
}

interface NavigationState {
    showMobileMenu: boolean,
}


class Navigation extends React.Component<Props, NavigationState> {
    constructor(props: any) {
        super(props);
        this.state = {showMobileMenu: false};
    }

    static defaultProps = {
        fixed: false,
    }

    onMobileMenuToggle = () => {
        this.setState({showMobileMenu: !this.state.showMobileMenu});
    }

    // TODO: гуард
    onMobileMenuClick = (e: any) => {
        if (e.target.tagName === 'A') {
            this.onMobileMenuToggle();
        }
    }

    render() {
        const className = classNames('navigation-container', {
            'navigation-fixed': this.props.fixed,
            'navigation-show': this.state.showMobileMenu
        });

        return (
            <nav className={className} onClick={this.onMobileMenuClick}>
                <NavLink className="navigation-link" to="/hookah">Кальяны</NavLink>
                <NavLink className="navigation-link navigation-before-logotype" to="/food-menu">Еда</NavLink>
                <NavLink className="navigation-logotype" to="/" title="Главная"/>
                <NavLink className="navigation-link" to="/about">О нас</NavLink>
                <NavLink className="navigation-link last" to="/contacts">Контакты</NavLink>
                <a className="navigation-social" href="https://www.vk.com/woodendoor">
                    <FontAwesomeIcon icon={faVk} size="2x"/>
                </a>
                <button className="navigation-button-container" onClick={this.onMobileMenuToggle}>
                    <span className="sr-only">Меню</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                {/*
               <button className="navigation-button-chat" onClick={this.onOpenChat}>
                    <span className="sr-only">Чат</span>
                    <span className="fa fa-comment"></span>
                </button>
                */}
                <div className="navigation-mobile-backside" onClick={this.onMobileMenuToggle}/>
            </nav>
        );
    }
}

export default Navigation;
