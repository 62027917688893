import React from "react";
import Navigation from "./Navigation";

const classNames = require('classnames');

//const {shouldComponentUpdate} = require('react-addons-pure-render-mixin');

interface Props {
    background: string,
    big: boolean,
    full: boolean,
    content: string,
    author: string,
    href: string,

    /*  propTypes: {
    quote: propTypes.shape({
      content: propTypes.string.isRequired,
      author: propTypes.string,
      href: propTypes.string
    })
  },*/
}


class Header extends React.Component<Props> {
    static defaultProps = {
        background: "/images/zdver-crowd.jpg",
        author: "Wooden Door",
        content: "Wooden Door — дымно и вкусно! Как у мамы.",
        big: false,
        full: false,
        href: "wdndoor",
    }

    constructor(props: any) {
        super(props);
        this.state = Header.defaultProps;
    }

    //shouldComponentUpdate ,
    getContainerStyle() {
        /*if (!this.props.background) {
            return undefined;
        }*/

        return {
            backgroundImage: `url(${this.props.background})`
        };
    }


    /*    renderQuoteAuthor() {
                if (!this.propTypes.quote.author) {
                  return null;
                }

            return <div className="header-quote-author">
                <a className="header-quote-author-link" href={this.props.href} target="_blank" rel="noreferrer">
                    {this.props.author}
                </a>
            </div>;
        }*/

    renderQuote() {
        if (!this.props) {
            return null;
        }

        return (
            <div className="header-quote text-center align-items-cente">
                <p>{this.props.content}</p>
                {/* {this.renderQuoteAuthor()}*/}
            </div>
        )

    }


    render() {
        const headerClasses = classNames('header-container', {
            'header-big': this.props.big,
            'header-full': this.props.full
        });

        return <header className={headerClasses} style={this.getContainerStyle()}>
            <div className="header-cover">
                <Navigation/>
                {this.renderQuote()}
            </div>
        </header>;
    }
}

export default Header;
