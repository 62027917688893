import React from 'react';
import {Helmet} from "react-helmet";
import PricingSlide from "./PricingSlide";
import animatedScrollTo from "../../components/utils/animated-scroll-to";
import Navigation from "../../layout/Navigation";
import SlideNav from "./SlideNav";
import Ribbon from "../../components/Ribbon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from '@fortawesome/free-solid-svg-icons'

interface Props {
    //onCreate: (formState: State) => void;
}

interface State {
    activeSlide: number
    innerWidth: number
    innerHeight: number
    slides: number[]
    scrollPending: boolean
    touchStart: number
}


//PureComponent
class About extends React.Component<Props, State> {
    static slidesCount = 5;
    static touchSensitivity = 5;

    // this.scrollToSlide(0);

    constructor(props: any) {
        super(props);
        this.state = {
            activeSlide: 0,
            innerHeight: 0,
            innerWidth: 0,
            slides: [],
            scrollPending: false,
            touchStart: 0,
        };
    }

    componentDidMount() {
        //document.addEventListener('wheel', this.onScroll);
        document.addEventListener('touchstart', this.onTouchStart);
        document.addEventListener('touchend', this.onTouchEnd);
        this.updateWindowDimensions();
        // TODO: лучше использовать ResizeObserver https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
        window.addEventListener('resize', this.updateWindowDimensions);
        /*
        const resizeObserver = new ResizeObserver((entries, observer) => {
            for(let entry of entries){
                console.log(entry);
            }
        })
        */
        //resizeObserver.observe()
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.onTouchStart);
        document.removeEventListener('touchstart', this.onTouchStart);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({slides: []})

        for (let i = 0; i < About.slidesCount; i++) {
            this.state.slides.push(window.innerHeight * i);
        }

        this.setState({innerWidth: window.innerWidth, innerHeight: window.innerHeight});
    }

    scrollToSlide = (slide: number) => {

        if (slide >= 0 && slide < About.slidesCount) {
            this.setState({
                activeSlide: slide,
                scrollPending: true,
            });

            animatedScrollTo(this.state.slides[slide], 700, () => {
                this.setState({scrollPending: true});
            });
        }
    };

    onArrowClick = () => {
        this.scrollToSlide(this.state.activeSlide + 1);
    };

    renderNavigation() {
        if (this.state.activeSlide === 0 || this.state.activeSlide === About.slidesCount - 1) {
            return <Navigation fixed/>;
        }
    }

    // TODO: использовать нормальный гуард
    onTouchStart = (e: any) => this.setState({touchStart: e.touches[0].clientY})

    // TODO: использовать нормальный гуард
    onTouchEnd(e: any) {
        const touchEnd = e.changedTouches[0].clientY;

        if (this.state.touchStart > touchEnd + About.touchSensitivity) {
            this.scrollToSlide(this.state.activeSlide + 1);
        } else if (this.state.touchStart < touchEnd - About.touchSensitivity) {
            this.scrollToSlide(this.state.activeSlide - 1);
        }
    }

    /*
    onScroll = (e: WheelEvent) => {
        console.log(e);
        console.log(this);
        console.log(this.state);
        e.preventDefault();

        if (this.scrollPending) {
            return false;
        }

        const scrollDown = (e.deltaY || -e.deltaY || -e.detail) < 0;

        let activeSlide = this.state.activeSlide;

        if (scrollDown) {
            activeSlide++;
        } else {
            activeSlide--;
        }

        this.scrollToSlide(activeSlide);
    }
    */

    render() {
        return <>
            <Helmet>
                <title>О нас</title>
            </Helmet>
            <div className="about">
                {this.renderNavigation()}
                <SlideNav active={this.state.activeSlide} onClick={this.scrollToSlide}/>

                <section className="about-slide about-slide-top">

                    <div className="about-title">
                        {<Ribbon title="Wooden Door" subTitle="Антикафе для друзей"/>}
                    </div>
                    <div className="about-arrow" onClick={this.onArrowClick}>
                        <FontAwesomeIcon icon={faAngleDown}/>
                    </div>
                </section>
                <section className="about-slide about-slide-yard"/>
                <section className="about-slide about-slide-location"/>
                <section className="about-slide about-slide-big"/>
                <PricingSlide/>
            </div>
        </>
    }
}


export default About;
