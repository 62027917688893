import React from "react";
import Additionals from "./Additionals";


interface MenuProps {
    name: string
    items: any
}

// TODO: super(props)
class MenuSection extends React.Component<MenuProps> {

/*    constructor(props:MenuProps) {
        super(props);
    }*/

    renderItems() {
        return this.props.items.map((item: any, itemIndex: any) => {
            const prices = [];
            for (let i = 0; i < item.price.length; i++) {
                let weight = item.weight[i];

                if (weight != null) {
                    weight += " гр";
                }

                const price = item.price[i];
                prices.push(
                    <span className="menu-section-item-price" key={i}>
                    {weight && `${weight} · `} <strong> {price + ' ₽'}</strong>
                        {/* <strong> {price + ' '} <span className="fa fa-rub"/></strong>*/}
                    </span>
                );
            }

            return (
                <>
                    <p className="menu-section-item" key={itemIndex}>
                        <span className="menu-section-item-name"> {item.name}</span>
                        {prices}
                    </p>
                    {
                        item.additionals
                            ? <Additionals items={item.additionals.items} title={item.additionals.title}/>
                            : <></>
                    }
                </>
            );
        });
    }

    render() {
        //const children = this.props;
        return (
            <section className="menu-section">
                <h2 className="menu-section-header">{this.props.name}</h2>
                {this.renderItems()}
            </section>
        );
    }
}

export default MenuSection;
