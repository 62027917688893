import React from "react";
import classNames from "classnames";


interface Props {
    active: number,
    onClick: (...args: any[]) => any;
}

const slides = [
    {},
    {
        title: 'Всегда весело!'
    },
    {
        title: 'Всегда дымно!'
    },
    {
        title: 'Всегда вкусно!'
    },
    {}
];


//TODO: проброс props в super
class SlideNav extends React.Component<Props> {
    static defaultProps = {
        active: 0,
        onClick: () => {
        }
    }

    renderNav() {
        return slides.map((slide, i) => {
            const navItemClass = classNames('about-nav-item', {
                active: this.props.active === i
            });

            //TODO: забиндить колбек можно один раз в конструкторе
            return <div className={navItemClass} onClick={this.props.onClick.bind(null, i)} key={i}/>

        });
    }

    render() {
        const activeSlide = slides[this.props.active];

        if (Object.keys(activeSlide).length !== 0) {
            return (
                <div className="about-slide-nav">
                    <h3>{activeSlide.title}</h3>
                    <p className="about-slide-nav-content">{activeSlide.title}</p>
                    {this.renderNav()}
                </div>
            );
        } else {
            return '';
        }

    }
}

export default SlideNav;
